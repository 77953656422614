import { Alert, emptyAlert } from "@/models/Alert.model";

import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import environmentConfig from "@/utils/environmentConfig";
import { InterfaceCostReportJob } from "@/models/CostReportJob.model";
import axios, { AxiosError, AxiosResponse } from "axios";

const baseApiJobsUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/async-jobs/cost-report`;

const baseApiCostReportUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/costs/reports`;

@Module({ namespaced: true })
class CostReportJobs extends VuexModule {
  public existingCostReports: InterfaceCostReportJob[] = [];
  public costReportAlert: Alert = emptyAlert();
  public costReportTableAlert: Alert = emptyAlert();
  public costReportDetailAlert: Alert = emptyAlert();
  public inProgress = false;

  @Action
  public async getCostReports(): Promise<boolean> {
    this.context.commit("setCostReports", []);
    this.context.commit("setInProgress", true);
    this.context.commit("clearCostReportTableAlert");
    return axios
      .get(`${baseApiJobsUrl}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setCostReports", response.data);
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit(
          "setCostReportTableAlert",
          new Alert(0, error.message)
        );
        this.context.commit("setInProgress", false);
        return false;
      });
  }

  @Action
  public async createCostReport(billingMonth: string): Promise<boolean> {
    this.context.commit("setInProgress", true);
    this.context.commit("clearCostReportAlert");
    return axios
      .post(`${baseApiCostReportUrl}?billingMonth=${billingMonth}`)
      .then(() => {
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit("setCostReportAlert", new Alert(0, error.message));
        this.context.commit("setInProgress", false);
        return false;
      });
  }

  @Action
  public async deleteCostReport(
    costReport: InterfaceCostReportJob
  ): Promise<boolean> {
    this.context.commit("setInProgress", true);
    this.context.commit("clearCostReportAlert");
    return axios
      .delete(`${baseApiJobsUrl}/${costReport.id}`)
      .then(() => {
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit("setCostReportAlert", new Alert(0, error.message));
        this.context.commit("setInProgress", false);
        return false;
      });
  }

  @Action
  public async deleteAllCostReports(): Promise<boolean> {
    this.context.commit("setInProgress", true);
    this.context.commit("clearCostReportAlert");
    return axios
      .delete(`${baseApiJobsUrl}`)
      .then(() => {
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit("setCostReportAlert", new Alert(0, error.message));
        this.context.commit("setInProgress", false);
        return false;
      });
  }

  @Mutation
  public clearCostReportTableAlert(): void {
    this.costReportTableAlert = emptyAlert();
  }

  @Mutation
  public setCostReports(data: InterfaceCostReportJob[]): void {
    this.existingCostReports = data;
  }

  @Mutation
  public setCostReportAlert(data: Alert): void {
    this.costReportAlert = data;
  }

  @Mutation
  public clearCostReportAlert(): void {
    this.costReportAlert = emptyAlert();
  }

  @Mutation
  public setCostReportTableAlert(data: Alert): void {
    this.costReportTableAlert = data;
  }

  @Mutation
  public setCostReportDetailAlert(data: Alert): void {
    this.costReportDetailAlert = data;
  }

  @Mutation
  public clearCostReportDetailAlert(): void {
    this.costReportDetailAlert = emptyAlert();
  }

  @Mutation
  public setInProgress(data: boolean): void {
    this.inProgress = data;
  }
}

export default CostReportJobs;
