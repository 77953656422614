import { Alert, emptyAlert } from "@/models/Alert.model";

import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import environmentConfig from "@/utils/environmentConfig";
import {
  InterfaceCostAzureSpJob,
  InterfaceCostAzureSpJobRequest,
} from "@/models/CostAzureSpJob.model";
import axios, { AxiosError, AxiosResponse } from "axios";

const baseApiJobsUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/async-jobs/azure-sp`;

const baseApiCostAzureSpUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/costs/azure/sp`;

@Module({ namespaced: true })
class CostAzureSpJobs extends VuexModule {
  public existingCostAzureSpJobs: InterfaceCostAzureSpJob[] = [];
  public costAzureSpJobsAlert: Alert = emptyAlert();
  public costAzureSpJobsTableAlert: Alert = emptyAlert();
  public costAzureSpJobsDetailAlert: Alert = emptyAlert();
  public inProgress = false;

  @Action
  public async getCostAzureSpJobs(): Promise<boolean> {
    this.context.commit("setCostAzureSpJobs", []);
    this.context.commit("setInProgress", true);
    this.context.commit("clearCostAzureSpJobsTableAlert");
    return axios
      .get(`${baseApiJobsUrl}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setCostAzureSpJobs", response.data);
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit(
          "setCostAzureSpJobsTableAlert",
          new Alert(0, error.message)
        );
        this.context.commit("setInProgress", false);
        return false;
      });
  }

  @Action
  public async createCostAzureSpJob(
    costAzureSpJobRequest: InterfaceCostAzureSpJobRequest
  ): Promise<boolean> {
    this.context.commit("setInProgress", true);
    this.context.commit("clearCostAzureSpJobsAlert");
    return axios
      .post(
        `${baseApiCostAzureSpUrl}/report?month=${costAzureSpJobRequest.month}`,
        costAzureSpJobRequest.authToken
      )
      .then(() => {
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit("setCostAzureSpAlert", new Alert(0, error.message));
        this.context.commit("setInProgress", false);
        return false;
      });
  }

  @Action
  public async deleteCostAzureSpJob(
    costAzureSpJob: InterfaceCostAzureSpJob
  ): Promise<boolean> {
    this.context.commit("setInProgress", true);
    this.context.commit("clearCostAzureSpJobsAlert");
    return axios
      .delete(`${baseApiJobsUrl}/${costAzureSpJob.id}`)
      .then(() => {
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit("setCostAzureSpAlert", new Alert(0, error.message));
        this.context.commit("setInProgress", false);
        return false;
      });
  }

  @Action
  public async deleteAllCostAzureSpJobs(): Promise<boolean> {
    this.context.commit("setInProgress", true);
    this.context.commit("clearCostAzureSpJobsAlert");
    return axios
      .delete(`${baseApiJobsUrl}`)
      .then(() => {
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit("setCostAzureSpAlert", new Alert(0, error.message));
        this.context.commit("setInProgress", false);
        return false;
      });
  }

  @Mutation
  public clearCostAzureSpJobsTableAlert(): void {
    this.costAzureSpJobsTableAlert = emptyAlert();
  }

  @Mutation
  public setCostAzureSpJobs(data: InterfaceCostAzureSpJob[]): void {
    this.existingCostAzureSpJobs = data;
  }

  @Mutation
  public setCostAzureSpAlert(data: Alert): void {
    this.costAzureSpJobsAlert = data;
  }

  @Mutation
  public clearCostAzureSpJobsAlert(): void {
    this.costAzureSpJobsAlert = emptyAlert();
  }

  @Mutation
  public setCostAzureSpJobsTableAlert(data: Alert): void {
    this.costAzureSpJobsTableAlert = data;
  }

  @Mutation
  public setCostAzureSpDetailAlert(data: Alert): void {
    this.costAzureSpJobsDetailAlert = data;
  }

  @Mutation
  public clearCostAzureSpDetailAlert(): void {
    this.costAzureSpJobsDetailAlert = emptyAlert();
  }

  @Mutation
  public setInProgress(data: boolean): void {
    this.inProgress = data;
  }
}

export default CostAzureSpJobs;
