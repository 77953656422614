import Vue from "vue";
import { Component, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { Alert } from "@/models/Alert.model";
import {
  emptyCostAtlassianJob,
  InterfaceCostAtlassianJob,
} from "@/models/CostAtlassianJob.model";

const costAtlassianJobs = namespace("CostAtlassianJobs");

@Component({})
export default class CostAtlassianJobs extends Vue {
  @Ref()
  createCostAtlassianJobFormElementBillingMonthField!: HTMLFormElement;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;

  public operationInProgress = false;

  public costAtlassianJob: InterfaceCostAtlassianJob = emptyCostAtlassianJob();
  public createCostAtlassianJobForm = false;
  public deleteCostAtlassianJobConfirmationForm = false;
  public deleteAllCostAtlassianJobsConfirmationForm = false;
  public billingMonth = "";

  @costAtlassianJobs.State
  public inProgress!: boolean;

  @costAtlassianJobs.State
  public costAtlassianJobsTableAlert!: Alert;

  @costAtlassianJobs.State
  public costAtlassianJobsAlert!: Alert;

  @costAtlassianJobs.State
  public existingCostAtlassianJobs!: InterfaceCostAtlassianJob[];

  @costAtlassianJobs.Action
  public getCostAtlassianJobs!: () => Promise<boolean>;

  @costAtlassianJobs.Action
  public createCostAtlassianJob!: (billingMonth: string) => Promise<boolean>;

  @costAtlassianJobs.Action
  public deleteCostAtlassianJob!: (
    costAtlassianJobs: InterfaceCostAtlassianJob
  ) => Promise<boolean>;

  @costAtlassianJobs.Action
  public deleteAllCostAtlassianJobs!: () => Promise<boolean>;

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const name = [
      { text: "Name", align: "start", value: "name", sortable: false },
    ];
    const status = [
      {
        text: "Status",
        align: "start",
        value: "status",
        sortable: false,
      },
    ];
    const message = [
      { text: "Message", align: "start", value: "message", sortable: false },
    ];
    const createdAt = [
      {
        text: "Created At",
        align: "start",
        value: "createdAt",
        sortable: false,
      },
    ];
    const updatedAt = [
      {
        text: "Updated At",
        align: "start",
        value: "updatedAt",
        sortable: false,
      },
    ];
    const actions = [
      { text: "Actions", align: "start", value: "actions", sortable: false },
    ];
    return id.concat(name, status, message, createdAt, updatedAt, actions);
  }

  public rules = {
    required: (value: string): string | boolean => !!value || "Required.",
    billingMonth: (value: string): boolean | string =>
      /^[2][0][2-9][0-9]-([0][1-9]|1[0-2])$/.test(value) ||
      "Invalid billing month format.",
  };

  public async mounted(): Promise<void> {
    await this.getCostAtlassianJobs();
  }

  public async getCostAtlassianJobsAction(): Promise<void> {
    this.operationInProgress = true;
    await this.getCostAtlassianJobs();
    this.operationInProgress = false;
  }

  public async createCostAtlassianJobAction(
    billingMonth: string
  ): Promise<void> {
    if (this.createCostAtlassianJobFormElementBillingMonthField.validate()) {
      this.operationInProgress = true;
      if (await this.createCostAtlassianJob(billingMonth)) {
        this.closeCreateCostAtlassianJobForm();
      }
      this.operationInProgress = false;
      this.getCostAtlassianJobsAction();
    }
    this.operationInProgress = false;
  }

  public async deleteCostAtlassianJobAction(
    costAtlassianJob: InterfaceCostAtlassianJob
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.deleteCostAtlassianJob(costAtlassianJob)) {
      this.closeDeleteCostAtlassianJobConfirmationForm();
    }
    this.operationInProgress = false;
    this.getCostAtlassianJobsAction();
  }

  public async deleteAllCostAtlassianJobsAction(): Promise<void> {
    this.operationInProgress = true;
    if (await this.deleteAllCostAtlassianJobs()) {
      this.closeDeleteAllCostAtlassianJobsConfirmationForm();
    }
    this.operationInProgress = false;
    this.getCostAtlassianJobsAction();
  }

  public createCostAtlassianJobFormDialog(): void {
    this.createCostAtlassianJobForm = true;
  }

  public deleteCostAtlassianJobDialog(
    costAtlassianJob: InterfaceCostAtlassianJob
  ): void {
    this.costAtlassianJob = costAtlassianJob;
    this.deleteCostAtlassianJobConfirmationForm = true;
  }

  public deleteAllCostAtlassianJobsDialog(): void {
    this.deleteAllCostAtlassianJobsConfirmationForm = true;
  }

  public closeCreateCostAtlassianJobForm(): void {
    this.createCostAtlassianJobForm = false;
  }

  public closeDeleteCostAtlassianJobConfirmationForm(): void {
    this.deleteCostAtlassianJobConfirmationForm = false;
  }

  public closeDeleteAllCostAtlassianJobsConfirmationForm(): void {
    this.deleteAllCostAtlassianJobsConfirmationForm = false;
  }
}
