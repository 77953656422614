import {
  InterfaceCostAtlassian,
  emptyCostAtlassian,
} from "@/models/CostAtlassian.model";
import { Component, Ref, Vue } from "vue-property-decorator";
import { Alert } from "@/models/Alert.model";
import { namespace } from "vuex-class";
import { InterfaceCostCenter } from "@/models/CostCenter.model";

const costAtlassian = namespace("CostAtlassian");
const costCenters = namespace("CostCenters");

interface FetchParams {
  costCenter: string;
  billingMonth: string;
}

@Component({
  components: {},
})
export default class CostAtlassianDetails extends Vue {
  @Ref()
  changeCostAtlassianOrganizationFormElement!: HTMLFormElement;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  public costAtlassian: InterfaceCostAtlassian = emptyCostAtlassian();

  @costAtlassian.State
  public atlassianCostDetailAlert!: Alert;

  @costAtlassian.State
  public selectedAtlassianCost!: InterfaceCostAtlassian;

  @costCenters.State
  public selectedCostCenter!: InterfaceCostCenter;

  @costAtlassian.State
  public confluenceUsers!: string[];

  @costAtlassian.State
  public jiraSoftwareUsers!: string[];

  @costAtlassian.State
  public jiraServiceManagementUsers!: string[];

  @costAtlassian.State
  public scaledAgileJiraSoftwareUsers!: string[];

  @costAtlassian.Mutation
  public setConfluenceUsers!: (confluenceUsers: string[]) => void;

  @costAtlassian.Mutation
  public setJiraSoftwareUsers!: (jiraSoftwareUsers: string[]) => void;

  @costAtlassian.Mutation
  public setJiraServiceManagementUsers!: (
    jiraServiceManagementUsers: string[]
  ) => void;

  @costAtlassian.Mutation
  public setScaledAgileJiraSoftwareUsers!: (
    scaledAgileJiraSoftwareUsers: string[]
  ) => void;

  @costAtlassian.Action
  public getAtlassianCost!: (fetchParams: FetchParams) => Promise<boolean>;

  @costCenters.Action
  public getCostCenter!: (id: string) => Promise<boolean>;

  async mounted(): Promise<void> {
    this.setEmptyUsersList().then(() => {
      this.getCostCenter(this.$router.currentRoute.params.id).then(() => {
        const fetchParams: FetchParams = {
          costCenter: this.selectedCostCenter.name,
          billingMonth: this.$router.currentRoute.params.month,
        };
        this.getAtlassianCost(fetchParams).then(() => {
          const confluenceUsersList =
            this.selectedAtlassianCost.details.atlassianServiceTypeMembers
              .MBM_CONFLUENCE_USERS;
          if (confluenceUsersList !== undefined) {
            this.setConfluenceUsers(
              confluenceUsersList.map((user) => user.emailAddress)
            );
          }

          const jiraSoftwareUsersList =
            this.selectedAtlassianCost.details.atlassianServiceTypeMembers
              .MBM_JIRA_SOFTWARE_USERS;
          if (jiraSoftwareUsersList !== undefined) {
            this.setJiraSoftwareUsers(
              jiraSoftwareUsersList.map((user) => user.emailAddress)
            );
          }

          const jiraServiceManagementUsersList =
            this.selectedAtlassianCost.details.atlassianServiceTypeMembers
              .MBM_JIRA_SERVICEMANAGEMENT_USERS;
          if (jiraServiceManagementUsersList !== undefined) {
            this.setJiraServiceManagementUsers(
              jiraServiceManagementUsersList.map((user) => user.emailAddress)
            );
          }

          const scaledAgileJiraSoftwareUsersList =
            this.selectedAtlassianCost.details.atlassianServiceTypeMembers
              .MBM_SCALED_AGILE_JIRA_SOFTWARE_USERS;
          if (scaledAgileJiraSoftwareUsersList !== undefined) {
            this.setScaledAgileJiraSoftwareUsers(
              scaledAgileJiraSoftwareUsersList.map((user) => user.emailAddress)
            );
          }
        });
      });
    });
  }

  public reload(): void {
    window.location.reload();
  }

  async setEmptyUsersList(): Promise<void> {
    this.setJiraSoftwareUsers([]);
    this.setConfluenceUsers([]);
    this.setJiraServiceManagementUsers([]);
    this.setScaledAgileJiraSoftwareUsers([]);
  }
}
