import Vue from "vue";
import { Component, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { Alert } from "@/models/Alert.model";
import {
  emptyCostReportJob,
  InterfaceCostReportJob,
} from "@/models/CostReportJob.model";

const costReports = namespace("CostReportJobs");

@Component({})
export default class CostReportAdmin extends Vue {
  @Ref()
  createCostReportFormElementBillingMonthField!: HTMLFormElement;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;

  public operationInProgress = false;

  public costReport: InterfaceCostReportJob = emptyCostReportJob();
  public createCostReportForm = false;
  public deleteCostReportConfirmationForm = false;
  public deleteAllCostReportsConfirmationForm = false;
  public billingMonth = "";

  @costReports.State
  public inProgress!: boolean;

  @costReports.State
  public costReportTableAlert!: Alert;

  @costReports.State
  public costReportAlert!: Alert;

  @costReports.State
  public existingCostReports!: InterfaceCostReportJob[];

  @costReports.Action
  public getCostReports!: () => Promise<boolean>;

  @costReports.Action
  public createCostReport!: (billingMonth: string) => Promise<boolean>;

  @costReports.Action
  public deleteCostReport!: (
    costReport: InterfaceCostReportJob
  ) => Promise<boolean>;

  @costReports.Action
  public deleteAllCostReports!: () => Promise<boolean>;

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const name = [
      { text: "Name", align: "start", value: "name", sortable: false },
    ];
    const status = [
      {
        text: "Status",
        align: "start",
        value: "status",
        sortable: false,
      },
    ];
    const message = [
      { text: "Message", align: "start", value: "message", sortable: false },
    ];
    const createdAt = [
      {
        text: "Created At",
        align: "start",
        value: "createdAt",
        sortable: false,
      },
    ];
    const updatedAt = [
      {
        text: "Updated At",
        align: "start",
        value: "updatedAt",
        sortable: false,
      },
    ];
    const actions = [
      { text: "Actions", align: "start", value: "actions", sortable: false },
    ];
    return id.concat(name, status, message, createdAt, updatedAt, actions);
  }

  public rules = {
    required: (value: string): string | boolean => !!value || "Required.",
    billingMonth: (value: string): boolean | string =>
      /^[2][0][2-9][0-9]-([0][1-9]|1[0-2])$/.test(value) ||
      "Invalid billing month format.",
  };

  public async mounted(): Promise<void> {
    await this.getCostReports();
  }

  public async getCostReportsAction(): Promise<void> {
    this.operationInProgress = true;
    await this.getCostReports();
    this.operationInProgress = false;
  }

  public async createCostReportAction(billingMonth: string): Promise<void> {
    if (this.createCostReportFormElementBillingMonthField.validate()) {
      this.operationInProgress = true;
      if (await this.createCostReport(billingMonth)) {
        this.closeCreateCostReportForm();
      }
      this.operationInProgress = false;
      this.getCostReportsAction();
    }
    this.operationInProgress = false;
  }

  public async deleteCostReportAction(
    costReport: InterfaceCostReportJob
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.deleteCostReport(costReport)) {
      this.closeDeleteCostReportConfirmationForm();
    }
    this.operationInProgress = false;
    this.getCostReportsAction();
  }

  public async deleteAllCostReportsAction(): Promise<void> {
    this.operationInProgress = true;
    if (await this.deleteAllCostReports()) {
      this.closeDeleteAllCostReportsConfirmationForm();
    }
    this.operationInProgress = false;
    this.getCostReportsAction();
  }

  public createCostReportFormDialog(): void {
    this.createCostReportForm = true;
  }

  public deleteCostReportDialog(costReport: InterfaceCostReportJob): void {
    this.costReport = costReport;
    this.deleteCostReportConfirmationForm = true;
  }

  public closeCreateCostReportForm(): void {
    this.createCostReportForm = false;
  }

  public closeDeleteCostReportConfirmationForm(): void {
    this.deleteCostReportConfirmationForm = false;
  }

  public deleteAllCostReportsDialog(): void {
    this.deleteAllCostReportsConfirmationForm = true;
  }

  public closeDeleteAllCostReportsConfirmationForm(): void {
    this.deleteAllCostReportsConfirmationForm = false;
  }
}
