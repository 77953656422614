export interface InterfaceCostReportJob {
  id: string;
  name: string;
  status: string;
  message: string;
  createdAt: string;
  updatedAt: string;
}

export const emptyCostReportJob = (): InterfaceCostReportJob => ({
  id: "",
  name: "",
  status: "",
  message: "",
  createdAt: "",
  updatedAt: "",
});

export class CostReportJob implements InterfaceCostReportJob {
  constructor(
    public id: string,
    public name: string,
    public status: string,
    public message: string,
    public createdAt: string,
    public updatedAt: string
  ) {}
}
