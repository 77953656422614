import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import environmentConfig from "./utils/environmentConfig";

export function initDatadog(): void {
  datadogRum.init({
    applicationId: environmentConfig("VUE_APP_DD_APP_ID"), // '4e808207-2649-4acb-b762-0bf78011b223',
    clientToken: environmentConfig("VUE_APP_DD_CLIENT_TOKEN"), // 'pub97482ca3ed58e9ffb232c62cbf65c2d5',
    site: "datadoghq.eu",
    service: "mazure-portal",
    env: environmentConfig("VUE_APP_DD_ENV"), // 'nonprod'
    version: environmentConfig("VUE_APP_DD_VERSION"), // '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogRum.setGlobalContextProperty("costcenterid", "8101201029");

  datadogLogs.init({
    clientToken: environmentConfig("VUE_APP_DD_CLIENT_TOKEN"), // 'pub97482ca3ed58e9ffb232c62cbf65c2d5',
    site: "datadoghq.eu",
    service: "mazure-portal",
    env: environmentConfig("VUE_APP_DD_ENV"), // 'nonprod'
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });
}
