export interface InterfaceCostAzureSp {
  id: string;
  creation: string;
  billingMonth: string;
  totalCost: number;
  currency: string;
  serviceId: string;
  chargedBy: string;
  costCenter: string;
  costCenterResponsible: string;
  costCenterResponsibleEmailAddress: string;
  costCenterNotificationUsers: string[];
  costDetails: InterfaceCostAzureSpDetails;
}

export const emptyCostAzureSp = (): InterfaceCostAzureSp => ({
  id: "",
  creation: "",
  billingMonth: "",
  totalCost: 0,
  currency: "",
  serviceId: "",
  chargedBy: "",
  costCenter: "",
  costCenterResponsible: "",
  costCenterResponsibleEmailAddress: "",
  costCenterNotificationUsers: [],
  costDetails: emptyCostAzureSpDetails(),
});

export interface InterfaceCostAzureSpDetails {
  collections: Map<string, number>;
  applications: Map<string, number>;
}

export const emptyCostAzureSpDetails = (): InterfaceCostAzureSpDetails => ({
  collections: new Map(),
  applications: new Map(),
});

export class CostAzureSp implements InterfaceCostAzureSp {
  constructor(
    public id: string,
    public creation: string,
    public billingMonth: string,
    public totalCost: number,
    public currency: string,
    public serviceId: string,
    public chargedBy: string,
    public costCenter: string,
    public costCenterResponsible: string,
    public costCenterResponsibleEmailAddress: string,
    public costCenterNotificationUsers: string[],
    public costDetails: InterfaceCostAzureSpDetails
  ) {}
}
