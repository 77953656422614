import {
  InterfaceAzureUser,
  InterfaceAzureUserSearchRequest,
  InterfaceAzureUserSearchResult,
} from "@/models/AzureUser.model";
import {
  emptySubscription,
  InterfaceSubscription,
  Subscription,
} from "@/models/Subscription.model";
import Vue from "vue";
import { Component, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { PageData } from "@/models/PageData.model";
import { InterfaceManagementGroup } from "@/models/ManagementGroup.model";
import { InterfacePortalUser } from "@/models/PortalUser.model";
import { InterfaceCostCenter } from "@/models/CostCenter.model";
import { Alert } from "@/models/Alert.model";
import { InterfaceOrganization } from "@/models/Organization.model";
import SearchInput from "../searchInput/SearchInput.vue";
import ServerSideAutocomplete from "../serverSideAutocomplete/ServerSideAutocomplete.vue";
import {
  emptySearchByKeywordRequest,
  SearchByKeywordRequest,
} from "@/models/Search.model";

const subscriptions = namespace("Subscriptions");
const managementGroups = namespace("ManagementGroups");
const organizations = namespace("Organizations");
const portalUsers = namespace("PortalUsers");
const costCenters = namespace("CostCenters");
const azureUsers = namespace("AzureUsers");

interface OrganizationSelectList {
  text: string;
  value: string;
}

interface ManagementGroupSelectList {
  text: string;
  value: string;
}

interface CostCenterSelectList {
  text: string;
  value: string;
}

interface FetchParams {
  search: string;
  page: number;
}

interface OrganizationCostCentersFetchParams {
  search: string;
  page: number;
  organizationId: string;
}

@Component({
  components: {
    ServerSideAutocomplete,
    SearchInput,
  },
})
export default class Subscriptions extends Vue {
  @Ref()
  createSubscriptionFormElement!: HTMLFormElement;
  @Ref()
  createSubscriptionFormElementNameField!: HTMLFormElement;
  @Ref()
  editSubscriptionFormElement!: HTMLFormElement;
  @Ref()
  costCenterAutocomplete!: HTMLFormElement;
  @Ref()
  organizationAutocomplete!: HTMLFormElement;
  @Ref()
  managementGroupAutocomplete!: HTMLFormElement;
  @Ref()
  createSubscriptionFormElementRemoteIdField!: HTMLFormElement;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  public selectedOrganizationId = "";
  public selectedManagementGroupId = "";
  public selectedInitialOwnerId = "";
  public selectedSubscriptionType = "";
  public subscription: InterfaceSubscription = emptySubscription();
  public selectedSubscription: InterfaceSubscription = emptySubscription();
  public createSubscriptionForm = false;
  public editSubscriptionForm = false;
  public deleteConfirmationForm = false;
  public cancelConfirmationForm = false;
  public organizationSelectList: OrganizationSelectList[] = [];
  public managementGroupSelectList: ManagementGroupSelectList[] = [];
  public costCenterSelectList: CostCenterSelectList[] = [];
  public subscriptionTypeSelectList: string[] = [
    "PRODUCTION",
    "NON_PRODUCTION",
    "SANDBOX",
  ];

  public subscriptionStateSelectList: string[] = [
    "ACTIVATING",
    "ACTIVATED",
    "ACTIVATION_FAILED",
    "MOVING_FAILED",
    "RENAMING_FAILED",
    "CHANGING_COST_CENTER_FAILED",
    "CANCELED",
    "CANCELLATION_FAILED",
  ];

  public hidden = false;
  public operationInProgress = false;
  public searchRequest: SearchByKeywordRequest = emptySearchByKeywordRequest();
  public searchAzureUsers = null;
  public azurePrincipalId = "";
  public nameOfSubscription = "";
  public deleteButton = false;
  public cancelButton = false;
  public remoteIdField = false;

  @managementGroups.State
  public managementGroupsSelectList!: InterfaceManagementGroup[];

  @managementGroups.State
  public pageDataManagementGroups!: PageData;

  @managementGroups.State
  public managementGroupsAutocompleteLoadMore!: boolean;

  @azureUsers.State
  public foundAzureUsers!: InterfaceAzureUser[];

  @costCenters.State
  public existingCostCenters!: InterfaceCostCenter[];

  @costCenters.State
  public costCenterAutocompleteLoadMore!: boolean;

  @costCenters.State
  public pageDataCostCenters!: PageData;

  @costCenters.Action
  public getOrganizationCostCenters!: (
    fetchParams: OrganizationCostCentersFetchParams
  ) => Promise<boolean>;

  @organizations.State
  public organizationsSelectList!: InterfaceOrganization[];

  @organizations.State
  public pageDataOrganizations!: PageData;

  @organizations.State
  public organizationAutocompleteLoadMore!: boolean;

  @organizations.Action
  public getOrganizationsSelectList!: (
    fetchParams: FetchParams
  ) => Promise<boolean>;

  @portalUsers.State
  public loggedInPortalUser!: InterfacePortalUser;

  @subscriptions.State
  public existingSubscriptions!: InterfaceSubscription[];

  @subscriptions.State
  public pageDataSubscriptions!: PageData;

  @subscriptions.State
  public subscriptionAlert!: Alert;

  @subscriptions.State
  public subscriptionTableAlert!: Alert;

  @subscriptions.State
  public toggleRemoteIdField!: boolean;

  @subscriptions.State
  public inProgress!: boolean;

  @subscriptions.Mutation
  public setPageData!: (pageData: PageData) => void;

  @subscriptions.Mutation
  public setToggleRemoteIdField!: (data: boolean) => void;

  @subscriptions.Mutation
  public clearSubscriptionAlert!: () => void;

  @subscriptions.Mutation
  public setSubscriptionAlert!: (alert: Alert) => void;

  @azureUsers.Mutation
  public setFoundAzureUsers!: (data: InterfaceAzureUser[]) => void;

  @azureUsers.Action
  public searchAzureUser!: (
    searchRequest: InterfaceAzureUserSearchRequest
  ) => Promise<InterfaceAzureUserSearchResult>;

  @managementGroups.Action
  public getManagementGroupSelectList!: (
    fetchParams: FetchParams
  ) => Promise<boolean>;

  @subscriptions.Action
  public getSubscriptions!: (
    searchRequest: SearchByKeywordRequest
  ) => Promise<boolean>;

  @subscriptions.Action
  public storeSubscription!: (
    subscription: InterfaceSubscription
  ) => Promise<boolean>;

  @subscriptions.Action
  public updateSubscription!: (
    subscription: InterfaceSubscription
  ) => Promise<boolean>;

  @subscriptions.Action
  public deleteSubscription!: (
    subscription: InterfaceSubscription
  ) => Promise<boolean>;

  @subscriptions.Action
  public cancelSubscription!: (
    subscription: InterfaceSubscription
  ) => Promise<boolean>;

  @subscriptions.Action
  public undoCancelSubscription!: (
    subscription: InterfaceSubscription
  ) => Promise<boolean>;

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const remoteId = [
      { text: "Remote ID", align: "start", value: "remoteId", sortable: false },
    ];
    const name = [
      { text: "Name", align: "start", value: "name", sortable: false },
    ];
    const type = [
      {
        text: "Subscription Type",
        align: "start",
        value: "subscriptionType",
        sortable: false,
      },
    ];
    const state = [
      {
        text: "Subscription State",
        align: "start",
        value: "subscriptionState",
        sortable: false,
      },
    ];
    const organizationName = [
      {
        text: "Organization",
        align: "start",
        value: "organizationName",
        sortable: false,
      },
    ];
    const managementGroupName = [
      {
        text: "Management Group",
        align: "start",
        value: "managementGroupName",
        sortable: false,
      },
    ];
    const costCenterName = [
      {
        text: "Cost Center",
        align: "start",
        value: "costCenterName",
        sortable: false,
      },
    ];
    const actions = [
      { text: "Actions", align: "start", value: "actions", sortable: false },
    ];
    if (this.$router.currentRoute.fullPath === "/azure/subscriptions") {
      if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
        return id.concat(
          remoteId,
          name,
          type,
          state,
          organizationName,
          managementGroupName,
          costCenterName,
          actions
        );
      }
      return remoteId.concat(
        name,
        type,
        state,
        organizationName,
        managementGroupName,
        costCenterName
      );
    }
    if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
      return id.concat(name, actions);
    }
    return name;
  }

  @Watch("pageDataSubscriptions.options", {
    deep: true,
  })
  optionsChange(): void {
    this.searchRequest.pageData.options = this.pageDataSubscriptions.options;
    this.contextLoadSubscriptions();
  }

  @Watch("searchRequest.keyword", {
    deep: true,
  })
  searchValueChange(): void {
    this.searchRequest.pageData.options = this.pageDataSubscriptions.options;
    this.contextLoadSubscriptions();
  }

  public rules = {
    required: (value: string): string | boolean => !!value || "Required.",
    name: (value: string): string | boolean => {
      const pattern = /^[a-zA-Z0-9-_]{3,50}$/;
      const onlyNumbers = /^\d+$/;
      return (
        (!onlyNumbers.test(value) && pattern.test(value)) ||
        "Invalid Subscription Name. Names must be a alphanumeric (without spaces) and at least 3 characters long and less than 50 characters."
      );
    },
    guid: (value: string): string | boolean => {
      const pattern = /^[a-fA-F0-9]{8}-([a-fA-F0-9]{4}-){3}[a-fA-F0-9]{12}$/;
      if (!value) {
        return "Required.";
      }
      return (
        pattern.test(value) ||
        "Invalid RemoteId. RemoteId must be a valid GUID."
      );
    },
  };

  private contextLoadSubscriptions() {
    if (this.$router.currentRoute.fullPath === "/azure/subscriptions") {
      this.loadAllSubscriptions();
    }
  }

  loadAllSubscriptions(): void {
    this.getSubscriptions(this.searchRequest);
  }

  public readSubscription(subscription: InterfaceSubscription): void {
    this.selectedSubscription = new Subscription(
      subscription.id,
      subscription.name,
      subscription.initialOwnerId,
      subscription.subscriptionType,
      subscription.organizationId,
      subscription.organizationName,
      subscription.managementGroupId,
      subscription.managementGroupName,
      subscription.subscriptionState,
      subscription.costCenterId,
      subscription.costCenterName,
      subscription.remoteId
    );
  }

  public editSubscriptionDialog(subscription: InterfaceSubscription): void {
    this.readSubscription(subscription);
    this.editSubscriptionForm = true;
  }

  public async editSubscriptionDialogAction(
    subscription: InterfaceSubscription
  ): Promise<void> {
    if (this.editSubscriptionFormElement.validate()) {
      this.operationInProgress = true;
      if (await this.updateSubscription(subscription)) {
        this.closeEditSubscriptionForm();
      }
      this.operationInProgress = false;
      this.contextLoadSubscriptions();
    }
  }

  public deleteSubscriptionDialog(subscription: InterfaceSubscription): void {
    this.readSubscription(subscription);
    this.deleteConfirmationForm = true;
  }

  public cancelSubscriptionDialog(subscription: InterfaceSubscription): void {
    this.readSubscription(subscription);
    this.cancelConfirmationForm = true;
  }

  public async deleteSubscriptionDialogAction(
    subscription: InterfaceSubscription
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.deleteSubscription(subscription)) {
      this.closeDeleteSubscriptionDialog();
    }
    this.operationInProgress = false;
    this.contextLoadSubscriptions();
  }

  public async cancelSubscriptionDialogAction(
    subscription: InterfaceSubscription
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.cancelSubscription(subscription)) {
      this.closeCancelSubscriptionDialog();
    }
    this.operationInProgress = false;
    this.contextLoadSubscriptions();
  }
  public async undoCancelSubscriptionDialogAction(
    subscription: InterfaceSubscription
  ): Promise<void> {
    this.operationInProgress = true;

    if (await this.undoCancelSubscription(subscription)) {
      this.closeCancelSubscriptionDialog();
    }
    this.operationInProgress = false;
    this.contextLoadSubscriptions();
  }

  public async storeSubscriptionDialogAction(
    subscription: InterfaceSubscription
  ): Promise<void> {
    if (this.createSubscriptionFormElement.validate()) {
      this.operationInProgress = true;
      if (await this.storeSubscription(subscription)) {
        this.closeCreateSubscriptionForm();
      }
      this.operationInProgress = false;
      this.selectedSubscription = emptySubscription();
      this.setSelectedOrganizationId();
      this.setSelectedSubscriptionType();
      this.contextLoadSubscriptions();
    }
  }

  public setSelectedOrganizationId(): void {
    this.selectedOrganizationId = "";
    if (
      this.subscription.organizationId != undefined ||
      this.subscription.organizationId != null
    ) {
      this.selectedOrganizationId = this.subscription.organizationId;
      this.subscription.costCenterId = "";
    }
  }

  public setSelectedSubscriptionType(): void {
    if (
      this.subscription.subscriptionType != undefined ||
      this.subscription.subscriptionType != null
    ) {
      this.selectedSubscriptionType = this.subscription.subscriptionType;
      this.subscription.initialOwnerId = "";
    }
  }

  public async fetchCreateSubscriptionManagementGroupSelectList(
    search: string,
    page: number
  ): Promise<Array<unknown>> {
    this.pageDataManagementGroups.pageIndex = page;
    await this.getManagementGroupSelectList({
      search: search,
      page: page,
    });
    return this.managementGroupsSelectList;
  }

  @Watch("searchAzureUsers")
  onPropertyChanged(value: string): void {
    let filteredValue = "";
    if (value && value.length > 2) {
      if (this.subscription.subscriptionType == "PRODUCTION") {
        filteredValue = "CSPX_A_" + value;
      } else {
        filteredValue = "CSTX_A_" + value;
      }
      const azureUserSearchRequest: InterfaceAzureUserSearchRequest = {
        displayName: filteredValue,
        pageIndex: 1,
        pageSize: 100,
      };
      this.searchAzureUser(azureUserSearchRequest);
    }
  }

  public async fetchCreateSubscriptionOrganizationSelectList(
    search: string,
    page: number
  ): Promise<Array<unknown>> {
    this.pageDataOrganizations.pageIndex = page;
    await this.getOrganizationsSelectList({
      search: search,
      page: page,
    });
    return this.organizationsSelectList;
  }

  public async fetchCreateSubscriptionCostCenterSelectList(
    search: string,
    page: number
  ): Promise<Array<unknown>> {
    if (this.selectedOrganizationId != "") {
      await this.getOrganizationCostCenters({
        search: search,
        page: page,
        organizationId: this.selectedOrganizationId,
      });
    }
    return this.existingCostCenters;
  }

  public closeCreateSubscriptionForm(): void {
    this.subscription = emptySubscription();
    this.createSubscriptionFormElement.reset();
    this.createSubscriptionForm = false;
    this.setFoundAzureUsers([]);
    this.selectedOrganizationId = "";
    this.selectedSubscriptionType = "";
    this.clearSubscriptionAlert();
  }

  public closeEditSubscriptionForm(): void {
    this.subscription = emptySubscription();
    this.editSubscriptionForm = false;
    this.clearSubscriptionAlert();
  }

  public closeDeleteSubscriptionDialog(): void {
    this.subscription = emptySubscription();
    this.nameOfSubscription = "";
    this.deleteConfirmationForm = false;
    this.clearSubscriptionAlert();
  }

  public closeCancelSubscriptionDialog(): void {
    this.subscription = emptySubscription();
    this.nameOfSubscription = "";
    this.cancelConfirmationForm = false;
    this.clearSubscriptionAlert();
  }

  @Watch("nameOfSubscription")
  showDeleteButtonChanged(): void {
    if (
      this.nameOfSubscription === this.selectedSubscription.name &&
      this.cancelConfirmationForm === true
    ) {
      this.cancelButton = true;
    } else {
      this.cancelButton = false;
    }
    if (
      this.nameOfSubscription === this.selectedSubscription.name &&
      this.deleteConfirmationForm === true
    ) {
      this.deleteButton = true;
    } else {
      this.deleteButton = false;
    }
  }
}
