export interface InterfaceCostAzureSpJob {
  id: string;
  name: string;
  status: string;
  message: string;
  createdAt: string;
  updatedAt: string;
}

export const emptyCostAzureSpJob = (): InterfaceCostAzureSpJob => ({
  id: "",
  name: "",
  status: "",
  message: "",
  createdAt: "",
  updatedAt: "",
});

export class CostAzureSpJob implements InterfaceCostAzureSpJob {
  constructor(
    public id: string,
    public name: string,
    public status: string,
    public message: string,
    public createdAt: string,
    public updatedAt: string
  ) {}
}

export interface InterfaceCostAzureSpJobRequest {
  month: string;
  authToken: string;
}

export const emptyCostAzureSpJobRequest =
  (): InterfaceCostAzureSpJobRequest => ({
    month: "",
    authToken: "",
  });

export class CostAzureSpJobRequest implements InterfaceCostAzureSpJobRequest {
  constructor(public month: string, public authToken: string) {}
}
