export interface InterfaceCostReport {
  id: string;
  costReportJson: string;
  billingMonth: string;
  createdAt: string;
}

export const emptyCostReport = (): InterfaceCostReport => ({
  id: "",
  costReportJson: "",
  billingMonth: "",
  createdAt: "",
});

export class CostReport implements InterfaceCostReport {
  constructor(
    public id: string,
    public costReportJson: string,
    public billingMonth: string,
    public createdAt: string
  ) {}
}
