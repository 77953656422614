import { Alert, emptyAlert } from "@/models/Alert.model";
import { InterfaceCostKubecostRawData } from "@/models/CostKubecostRawData.model";
import { emptyPageData, PageData } from "@/models/PageData.model";
import environmentConfig from "@/utils/environmentConfig";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

interface FetchParams {
  startDate: string;
  endDate: string;
}

const baseApiUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/costs/azure/kubecost/report/weekly`;

@Module({ namespaced: true })
class CostKubecostRawData extends VuexModule {
  public costKubecostRawDataTableAlert: Alert = emptyAlert();
  public rangeDate: string[] = [];
  public inProgress = false;
  public pageDataKubecostRawData: PageData = emptyPageData();
  public existingRawData: InterfaceCostKubecostRawData[] = [];

  @Mutation
  public setKubecostRawDataTableAlert(data: Alert): void {
    this.costKubecostRawDataTableAlert = data;
  }

  @Mutation
  public clearKubecostRawDataTableAlert(): void {
    this.costKubecostRawDataTableAlert = emptyAlert();
  }

  @Mutation
  public setRangeDate(data: string[]): void {
    this.rangeDate = data;
  }

  @Mutation
  public setRawData(data: InterfaceCostKubecostRawData[]): void {
    this.existingRawData = data;
  }

  @Mutation
  public setPageData(pageData: PageData): void {
    this.pageDataKubecostRawData.totalItems = pageData.totalItems;
  }

  @Mutation
  public setInProgress(data: boolean): void {
    this.inProgress = data;
  }

  @Action
  public async getKubecostRawData(params: FetchParams): Promise<boolean> {
    this.context.commit("setInProgress", true);
    this.context.commit("setKubecostRawDataTableAlert", emptyAlert());
    this.context.commit("setRangeDate", [params.startDate, params.endDate]);
    return axios
      .get(
        `${baseApiUrl}?startDate=${params.startDate}&endDate=${params.endDate}`
      )
      .then((response: AxiosResponse) => {
        this.context.commit("setRawData", response.data);
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit(
          "setKubecostRawDataTableAlert",
          new Alert(0, error.message)
        );
        this.context.commit("setInProgress", false);
        return false;
      });
  }
}

export default CostKubecostRawData;
