import { Alert, emptyAlert } from "@/models/Alert.model";

import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import environmentConfig from "@/utils/environmentConfig";
import { InterfaceCostAtlassianJob } from "@/models/CostAtlassianJob.model";
import axios, { AxiosError, AxiosResponse } from "axios";

const baseApiJobsUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/async-jobs/atlassian`;

const baseApiCostAtlassianUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/costs/atlassian`;

@Module({ namespaced: true })
class CostAtlassianJobs extends VuexModule {
  public existingCostAtlassianJobs: InterfaceCostAtlassianJob[] = [];
  public costAtlassianJobsAlert: Alert = emptyAlert();
  public costAtlassianJobsTableAlert: Alert = emptyAlert();
  public costAtlassianJobsDetailAlert: Alert = emptyAlert();
  public inProgress = false;

  @Action
  public async getCostAtlassianJobs(): Promise<boolean> {
    this.context.commit("setCostAtlassianJobs", []);
    this.context.commit("setInProgress", true);
    this.context.commit("clearCostAtlassianJobsTableAlert");
    return axios
      .get(`${baseApiJobsUrl}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setCostAtlassianJobs", response.data);
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit(
          "setCostAtlassianJobsTableAlert",
          new Alert(0, error.message)
        );
        this.context.commit("setInProgress", false);
        return false;
      });
  }

  @Action
  public async createCostAtlassianJob(month: string): Promise<boolean> {
    this.context.commit("setInProgress", true);
    this.context.commit("clearCostAtlassianJobsAlert");
    return axios
      .post(`${baseApiCostAtlassianUrl}/report?month=${month}`)
      .then(() => {
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit(
          "setCostAtlassianAlert",
          new Alert(0, error.message)
        );
        this.context.commit("setInProgress", false);
        return false;
      });
  }

  @Action
  public async deleteCostAtlassianJob(
    costAtlassianJob: InterfaceCostAtlassianJob
  ): Promise<boolean> {
    this.context.commit("setInProgress", true);
    this.context.commit("clearCostAtlassianJobsAlert");
    return axios
      .delete(`${baseApiJobsUrl}/${costAtlassianJob.id}`)
      .then(() => {
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit(
          "setCostAtlassianAlert",
          new Alert(0, error.message)
        );
        this.context.commit("setInProgress", false);
        return false;
      });
  }

  @Action
  public async deleteAllCostAtlassianJobs(): Promise<boolean> {
    this.context.commit("setInProgress", true);
    this.context.commit("clearCostAtlassianJobsAlert");
    return axios
      .delete(`${baseApiJobsUrl}`)
      .then(() => {
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit(
          "setCostAtlassianAlert",
          new Alert(0, error.message)
        );
        this.context.commit("setInProgress", false);
        return false;
      });
  }

  @Mutation
  public clearCostAtlassianJobsTableAlert(): void {
    this.costAtlassianJobsTableAlert = emptyAlert();
  }

  @Mutation
  public setCostAtlassianJobs(data: InterfaceCostAtlassianJob[]): void {
    this.existingCostAtlassianJobs = data;
  }

  @Mutation
  public setCostAtlassianAlert(data: Alert): void {
    this.costAtlassianJobsAlert = data;
  }

  @Mutation
  public clearCostAtlassianJobsAlert(): void {
    this.costAtlassianJobsAlert = emptyAlert();
  }

  @Mutation
  public setCostAtlassianJobsTableAlert(data: Alert): void {
    this.costAtlassianJobsTableAlert = data;
  }

  @Mutation
  public setCostAtlassianDetailAlert(data: Alert): void {
    this.costAtlassianJobsDetailAlert = data;
  }

  @Mutation
  public clearCostAtlassianDetailAlert(): void {
    this.costAtlassianJobsDetailAlert = emptyAlert();
  }

  @Mutation
  public setInProgress(data: boolean): void {
    this.inProgress = data;
  }
}

export default CostAtlassianJobs;
