import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueKeycloakJs from "@dsb-norge/vue-keycloak-js";
import environmentConfig from "@/utils/environmentConfig";
import { initDatadog } from "./datadog";
import { datadogRum } from "@datadog/browser-rum";

Vue.config.productionTip = false;

function tokenInterceptor() {
  axios.interceptors.request.use(
    (config) => {
      if (Vue.prototype.$keycloak.authenticated) {
        config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}

// DataDog error handler
Vue.config.errorHandler = function (err, vm, info) {
  // eslint-disable-next-line no-console
  console.error("Vue error handler: ", err, vm, info);
  datadogRum.addError(err, {
    message: info,
    vueComponent: vm.$options.name,
  });
};

Vue.use(VueKeycloakJs, {
  init: {
    // Use 'login-required' to always require authentication
    // If using 'login-required', there is no need for the router guards in router.js
    onLoad: "login-required",
  },

  // Configuration values are used from '.env' files
  config: {
    url: environmentConfig("VUE_APP_KEYCLOAK_URL"),
    realm: environmentConfig("VUE_APP_KEYCLOAK_REALM"),
    clientId: environmentConfig("VUE_APP_KEYCLOAK_CLIENT"),
  },

  onReady: (keycloak) => {
    // eslint-disable-next-line no-console
    console.log(`Login User ID Keycloak: ${keycloak.subject}`);
    initDatadog();
    tokenInterceptor();

    router.onError((error) => {
      datadogRum.addError(error, { type: "router" });
    });

    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  },
});
