import { Alert, emptyAlert } from "@/models/Alert.model";
import {
  InterfaceCostAzureGlobal,
  emptyCostAzureGlobal,
} from "@/models/CostAzureGlobal.model";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import axios, { AxiosError, AxiosResponse } from "axios";
import environmentConfig from "@/utils/environmentConfig";
import { InterfaceCostAzureSub } from "@/models/CostAzureSub.model";
import { InterfaceCostCloudExcellence } from "@/models/CostCloudExcellence.model";
import { InterfaceCostAzureHub } from "@/models/CostAzureHub.model";
import { InterfaceCostAzureSp } from "@/models/CostAzureSp.model";
import { InterfaceCostKubecost } from "@/models/CostKubecost.model";

const baseApiUrlSubscription = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/costs/azure/subscriptions`;

const baseApiUrlCloudExcellence = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/costs/cloud-excellence`;

const baseApiUrlHub = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/costs/azure/hub`;

const baseApiUrlSp = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/costs/azure/sp`;

const baseApiUrlKubecost = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/costs/azure/kubecost`;

@Module({ namespaced: true })
class CostAzureGlobal extends VuexModule {
  public selectedAzureGlobalCost: InterfaceCostAzureGlobal =
    emptyCostAzureGlobal();
  public azureGlobalCostDetailAlert: Alert = emptyAlert();
  public costAzureGlobalTableAlert: Alert = emptyAlert();
  public selectedAzureGlobalCostsList: InterfaceCostAzureGlobal[] = [];
  public month = "";
  public rangeDate: string[] = [];

  @Mutation
  public setSelectedAzureGlobalCost(data: InterfaceCostAzureGlobal): void {
    this.selectedAzureGlobalCost = data;
  }

  @Mutation
  public setAzureGlobalCostDetailAlert(data: Alert): void {
    this.azureGlobalCostDetailAlert = data;
  }

  @Mutation
  public setSelectedAzureGlobalCostsList(
    data: InterfaceCostAzureGlobal[]
  ): void {
    this.selectedAzureGlobalCostsList = data;
  }

  @Mutation
  public setAzureGlobalCostListAlert(data: Alert): void {
    this.costAzureGlobalTableAlert = data;
  }

  @Mutation
  public clearAzureGlobalCostListAlert(): void {
    this.costAzureGlobalTableAlert = emptyAlert();
  }

  @Mutation
  public setRangeDate(data: string[]): void {
    this.rangeDate = data;
  }

  @Action
  public async getAzureGlobalCosts(): Promise<boolean> {
    this.context.commit("setSelectedAzureGlobalCostsList", []);

    // Add all the axios calls to an array
    const globalCosts: InterfaceCostAzureGlobal[] = [];

    // Fetch the data from the API based on the range date
    await Promise.all(
      this.rangeDate.map((date) => {
        return axios
          .get(`${baseApiUrlSubscription}?month=${date}`)
          .then((response: AxiosResponse) => {
            response.data.forEach((item: InterfaceCostAzureSub) => {
              const globalCost: InterfaceCostAzureGlobal = {
                id: item.id,
                creation: item.creation,
                costDetails: item.subscriptionName,
                serviceType: "AZURE_SUBSCRIPTION",
                billingMonth: item.billingMonth,
                totalCost: item.totalCost,
                currency: item.currency,
                serviceId: item.serviceId,
                chargedBy: item.chargedBy,
                costCenter: item.costCenter,
                costCenterResponsible: item.costCenterResponsible,
                costCenterResponsibleEmailAddress:
                  item.costCenterResponsibleEmailAddress,
                costCenterNotificationUsers: item.costCenterNotificationUsers,
              };
              globalCosts.push(globalCost);
            });
          });
      })
    );
    await Promise.all(
      this.rangeDate.map((date) => {
        return axios
          .get(`${baseApiUrlCloudExcellence}?month=${date}`)
          .then((response: AxiosResponse) => {
            response.data.forEach((item: InterfaceCostCloudExcellence) => {
              const globalCost: InterfaceCostAzureGlobal = {
                id: item.id,
                creation: item.creation,
                costDetails: item.subscriptionName,
                serviceType: "CLOUD_EXCELLENCE",
                billingMonth: item.billingMonth,
                totalCost: item.totalCost,
                currency: item.currency,
                serviceId: item.serviceId,
                chargedBy: item.chargedBy,
                costCenter: item.costCenter,
                costCenterResponsible: item.costCenterResponsible,
                costCenterResponsibleEmailAddress:
                  item.costCenterResponsibleEmailAddress,
                costCenterNotificationUsers: item.costCenterNotificationUsers,
              };
              globalCosts.push(globalCost);
            });
          });
      })
    );
    await Promise.all(
      this.rangeDate.map((date) => {
        return axios
          .get(`${baseApiUrlHub}?month=${date}`)
          .then((response: AxiosResponse) => {
            response.data.forEach((item: InterfaceCostAzureHub) => {
              const globalCost: InterfaceCostAzureGlobal = {
                id: item.id,
                creation: item.creation,
                costDetails: "mAzure Hub",
                serviceType: "AZURE_HUB",
                billingMonth: item.billingMonth,
                totalCost: item.totalCost,
                currency: item.currency,
                serviceId: item.serviceId,
                chargedBy: item.chargedBy,
                costCenter: item.costCenter,
                costCenterResponsible: item.costCenterResponsible,
                costCenterResponsibleEmailAddress:
                  item.costCenterResponsibleEmailAddress,
                costCenterNotificationUsers: item.costCenterNotificationUsers,
              };
              globalCosts.push(globalCost);
            });
          });
      })
    );
    await Promise.all(
      this.rangeDate.map((date) => {
        return axios
          .get(`${baseApiUrlSp}?month=${date}`)
          .then((response: AxiosResponse) => {
            response.data.forEach((item: InterfaceCostAzureSp) => {
              const globalCost: InterfaceCostAzureGlobal = {
                id: item.id,
                creation: item.creation,
                costDetails: "mAzure Service Principals",
                serviceType: "AZURE_SERVICE_PRINCIPALS",
                billingMonth: item.billingMonth,
                totalCost: item.totalCost,
                currency: item.currency,
                serviceId: item.serviceId,
                chargedBy: item.chargedBy,
                costCenter: item.costCenter,
                costCenterResponsible: item.costCenterResponsible,
                costCenterResponsibleEmailAddress:
                  item.costCenterResponsibleEmailAddress,
                costCenterNotificationUsers: item.costCenterNotificationUsers,
              };
              globalCosts.push(globalCost);
            });
          });
      })
    );
    await Promise.all(
      this.rangeDate.map((date) => {
        return axios
          .get(`${baseApiUrlKubecost}?month=${date}`)
          .then((response: AxiosResponse) => {
            // each item transform to InterfaceCostAzureGlobal
            response.data.forEach((item: InterfaceCostKubecost) => {
              const globalCost: InterfaceCostAzureGlobal = {
                id: item.id,
                creation: item.creation,
                costDetails:
                  item.subscriptionName +
                  " / " +
                  item.kubernetesCluster +
                  " / " +
                  item.namespace,
                serviceType: "AZURE_KUBECOST",
                billingMonth: item.billingMonth,
                totalCost: item.totalCost,
                currency: item.currency,
                serviceId: item.serviceId,
                chargedBy: item.chargedBy,
                costCenter: item.costCenter,
                costCenterResponsible: item.costCenterResponsible,
                costCenterResponsibleEmailAddress:
                  item.costCenterResponsibleEmailAddress,
                costCenterNotificationUsers: item.costCenterNotificationUsers,
              };
              globalCosts.push(globalCost);
            });
          });
      })
    );

    // Set the selectedAzureGlobalCostsList with the data
    this.context.commit("setSelectedAzureGlobalCostsList", globalCosts);
    // Set the costAzureGlobalTableAlert with the data
    this.context.commit("setAzureGlobalCostListAlert", emptyAlert());
    return true;
  }
  catch(error: AxiosError): boolean {
    const axiosError = error as AxiosError;
    let alert: Alert;
    if (axiosError.response) {
      const data = axiosError.response.data as { message: string };
      alert = new Alert(axiosError.response.status, data.message);
    } else {
      alert = new Alert(0, axiosError.message);
    }
    this.context.commit("setAzureGlobalCostListAlert", alert, { root: true });
    return false;
  }
}

export default CostAzureGlobal;
