import {
  InterfaceCostAzureSp,
  emptyCostAzureSp,
} from "@/models/CostAzureSp.model";
import { Component, Ref, Vue } from "vue-property-decorator";
import { Alert } from "@/models/Alert.model";
import { namespace } from "vuex-class";
import { InterfaceCostCenter } from "@/models/CostCenter.model";

const costAzureSp = namespace("CostAzureSp");
const costCenters = namespace("CostCenters");

interface FetchParams {
  costCenter: string;
  billingMonth: string;
}

@Component({
  components: {},
})
export default class CostAzureSpDetails extends Vue {
  @Ref()
  changeCostAzureSpOrganizationFormElement!: HTMLFormElement;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  public costAzureSp: InterfaceCostAzureSp = emptyCostAzureSp();

  @costAzureSp.State
  public azureSpCostDetailAlert!: Alert;

  @costAzureSp.State
  public selectedAzureSpCost!: InterfaceCostAzureSp;

  @costCenters.State
  public selectedCostCenter!: InterfaceCostCenter;

  @costAzureSp.State
  public collections!: Map<string, number>;

  @costAzureSp.State
  public applications!: Map<string, number>;

  @costAzureSp.Mutation
  public setCollections!: (collections: Map<string, number>) => void;

  @costAzureSp.Mutation
  public setApplications!: (applications: Map<string, number>) => void;

  @costAzureSp.Action
  public getAzureSpCost!: (fetchParams: FetchParams) => Promise<boolean>;

  @costCenters.Action
  public getCostCenter!: (id: string) => Promise<boolean>;

  async mounted(): Promise<void> {
    this.setCostDetailsList().then(() => {
      this.getCostCenter(this.$router.currentRoute.params.id).then(() => {
        const fetchParams: FetchParams = {
          costCenter: this.selectedCostCenter.name,
          billingMonth: this.$router.currentRoute.params.month,
        };
        this.getAzureSpCost(fetchParams).then(() => {
          const collections = this.selectedAzureSpCost.costDetails.collections;

          if (collections !== undefined) {
            this.setCollections(collections);
          }

          const applications =
            this.selectedAzureSpCost.costDetails.applications;

          if (applications !== undefined) {
            this.setApplications(applications);
          }
        });
      });
    });
  }

  public reload(): void {
    window.location.reload();
  }

  async setCostDetailsList(): Promise<void> {
    this.setCollections(new Map());
    this.setApplications(new Map());
  }
}
