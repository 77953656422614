import {
  InterfaceCostCenterServicesResult,
  InterfaceService,
} from "@/models/CostCenter.model";
import { Chart } from "chart.js";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import moment from "moment";

const costCenters = namespace("CostCenters");

interface Service {
  serviceName: string;
  serviceCost: number;
  serviceDate: string;
}

interface DataSet {
  label: string;
  data: number[];
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
  fill: boolean;
}
@Component
export default class CostCenterLineChart extends Vue {
  @Prop({ required: true }) startDate!: string;
  @Prop({ required: true }) endDate!: string;

  public chart!: Chart;
  chartCreated = false;
  @costCenters.State
  public costCenterServices!: InterfaceCostCenterServicesResult[];

  @costCenters.Mutation
  public setBillingMonth!: (data: string) => void;

  @costCenters.Action
  public getCostCenterServices!: (id: string) => Promise<boolean>;

  public changeCostCenterServices = this.costCenterServices;

  generateColor(index: number): string {
    const hue = (index * 137.508) % 360; // Use a prime number for better distribution
    const saturation = 90;
    const lightness = 50;
    const alpha = 1;

    return `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`;
  }

  chartData(): { labels: string[]; datasets: DataSet[] } {
    const dates: string[] = [];
    const services: Service[] = [];
    const dataSets: DataSet[] = [];

    const sortedCostCenterServices = this.costCenterServices
      .slice()
      .sort(
        (a, b) =>
          new Date(a.billingMonth).getTime() -
          new Date(b.billingMonth).getTime()
      );

    sortedCostCenterServices.forEach(
      (value: InterfaceCostCenterServicesResult) => {
        dates.push(value.billingMonth);
        if (value.services.length > 0) {
          value.services.forEach((service: InterfaceService) => {
            const serviceElement = {
              serviceName: service.serviceName,
              serviceCost: service.cost,
              serviceDate: value.billingMonth || "",
            };
            services.push(serviceElement);
          });
          const totalServicesElement = {
            serviceName: "TOTAL COST",
            serviceCost: value.totalCost,
            serviceDate: value.billingMonth || "",
          };
          services.push(totalServicesElement);
        }
      }
    );
    const addMissingCost: Service[] = [];

    for (const serviceName of Array.from(
      new Set(services.map((service) => service.serviceName))
    )) {
      for (const date of dates) {
        const matchingDate = services.find(
          (service) =>
            service.serviceName === serviceName && service.serviceDate === date
        );
        if (matchingDate) {
          addMissingCost.push(matchingDate);
        } else {
          addMissingCost.push({
            serviceName: serviceName,
            serviceCost: 0,
            serviceDate: date,
          });
        }
      }
    }

    if (addMissingCost.length > 0) {
      const serviceNames = new Set(services.map((item) => item.serviceName));
      const sortedAddMissingCost = addMissingCost
        .slice()
        .sort(
          (a, b) =>
            new Date(a.serviceDate).getTime() -
            new Date(b.serviceDate).getTime()
        );
      Array.from(serviceNames).forEach((serviceName, index) => {
        const color = this.generateColor(index);
        const serviceCosts: number[] = [];
        sortedAddMissingCost.forEach((item: Service) => {
          if (item.serviceName === serviceName) {
            serviceCosts.push(item.serviceCost);
          }
        });
        const dataSet = {
          label: serviceName,
          data: serviceCosts,
          backgroundColor: color,
          borderColor: color,
          borderWidth: 1,
          fill: false,
        };
        dataSets.push(dataSet);
      });
    }

    return {
      labels: dates,
      datasets: dataSets,
    };
  }

  public chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };

  beforeDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  async mounted(): Promise<void> {
    if (this.costCenterServices) {
      this.createChart();
    }
  }

  getDatesSelected(): string[] {
    const dates: string[] = [];
    if (this.startDate && this.endDate) {
      const startDate: moment.Moment = moment(this.startDate).startOf("month");
      const endDate: moment.Moment = moment(this.endDate).endOf("month");
      const currentDate: moment.Moment = startDate.clone();
      while (currentDate.isSameOrBefore(endDate)) {
        dates.push(currentDate.format("YYYY-MM"));
        currentDate.add(1, "month");
      }
    }
    return dates;
  }

  createChart(): void {
    const canvas = this.$refs.chart as HTMLCanvasElement;
    const chartData = this.chartData();
    this.chart = new Chart(canvas, {
      type: "line",
      data: chartData,
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    });
  }

  @Watch("costCenterServices", {})
  async onCostCenterServicesChange(): Promise<void> {
    if (
      JSON.stringify(this.costCenterServices) !==
      JSON.stringify(this.changeCostCenterServices)
    ) {
      this.changeCostCenterServices = this.costCenterServices;
      if (this.chart) {
        this.chart.destroy();
        this.createChart();
      }
    }
  }
}
