import Vue from "vue";
import { Component, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { Alert } from "@/models/Alert.model";
import {
  emptyCostAzureSpJob,
  emptyCostAzureSpJobRequest,
  InterfaceCostAzureSpJob,
  InterfaceCostAzureSpJobRequest,
} from "@/models/CostAzureSpJob.model";

const costAzureSpJobs = namespace("CostAzureSpJobs");

@Component({})
export default class CostAzureSpJobs extends Vue {
  @Ref()
  createCostAzureSpJobFormElementBillingMonthField!: HTMLFormElement;

  @Ref()
  createCostAzureSpJobFormElementAuthTokenField!: HTMLFormElement;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;

  public operationInProgress = false;

  public costAzureSpJob: InterfaceCostAzureSpJob = emptyCostAzureSpJob();
  public createCostAzureSpJobForm = false;
  public deleteCostAzureSpJobConfirmationForm = false;
  public deleteAllCostAzureSpJobsConfirmationForm = false;
  public billingMonth = "";
  public authToken = "";
  public costAzureSpJobRequest: InterfaceCostAzureSpJobRequest =
    emptyCostAzureSpJobRequest();

  @costAzureSpJobs.State
  public inProgress!: boolean;

  @costAzureSpJobs.State
  public costAzureSpJobsTableAlert!: Alert;

  @costAzureSpJobs.State
  public costAzureSpJobsAlert!: Alert;

  @costAzureSpJobs.State
  public existingCostAzureSpJobs!: InterfaceCostAzureSpJob[];

  @costAzureSpJobs.Action
  public getCostAzureSpJobs!: () => Promise<boolean>;

  @costAzureSpJobs.Action
  public createCostAzureSpJob!: (
    costAzureSpJobRequest: InterfaceCostAzureSpJobRequest
  ) => Promise<boolean>;

  @costAzureSpJobs.Action
  public deleteCostAzureSpJob!: (
    costAzureSpJobs: InterfaceCostAzureSpJob
  ) => Promise<boolean>;

  @costAzureSpJobs.Action
  public deleteAllCostAzureSpJobs!: () => Promise<boolean>;

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const name = [
      { text: "Name", align: "start", value: "name", sortable: false },
    ];
    const status = [
      {
        text: "Status",
        align: "start",
        value: "status",
        sortable: false,
      },
    ];
    const message = [
      { text: "Message", align: "start", value: "message", sortable: false },
    ];
    const createdAt = [
      {
        text: "Created At",
        align: "start",
        value: "createdAt",
        sortable: false,
      },
    ];
    const updatedAt = [
      {
        text: "Updated At",
        align: "start",
        value: "updatedAt",
        sortable: false,
      },
    ];
    const actions = [
      { text: "Actions", align: "start", value: "actions", sortable: false },
    ];
    return id.concat(name, status, message, createdAt, updatedAt, actions);
  }

  public rules = {
    required: (value: string): string | boolean => !!value || "Required.",
    billingMonth: (value: string): boolean | string =>
      /^[2][0][2-9][0-9]-([0][1-9]|1[0-2])$/.test(value) ||
      "Invalid billing month format.",
  };

  public async mounted(): Promise<void> {
    await this.getCostAzureSpJobs();
  }

  public async getCostAzureSpJobsAction(): Promise<void> {
    this.operationInProgress = true;
    await this.getCostAzureSpJobs();
    this.operationInProgress = false;
  }

  public async createCostAzureSpJobAction(
    month: string,
    authToken: string
  ): Promise<void> {
    if (
      this.createCostAzureSpJobFormElementBillingMonthField.validate() &&
      this.createCostAzureSpJobFormElementAuthTokenField.validate()
    ) {
      this.operationInProgress = true;
      this.costAzureSpJobRequest = {
        month,
        authToken,
      };
      if (await this.createCostAzureSpJob(this.costAzureSpJobRequest)) {
        this.closeCreateCostAzureSpJobForm();
      }
      this.operationInProgress = false;
      this.getCostAzureSpJobsAction();
    }
    this.operationInProgress = false;
  }

  public async deleteCostAzureSpJobAction(
    costAzureSpJob: InterfaceCostAzureSpJob
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.deleteCostAzureSpJob(costAzureSpJob)) {
      this.closeDeleteCostAzureSpJobConfirmationForm();
    }
    this.operationInProgress = false;
    this.getCostAzureSpJobsAction();
  }

  public async deleteAllCostAzureSpJobsAction(): Promise<void> {
    this.operationInProgress = true;
    if (await this.deleteAllCostAzureSpJobs()) {
      this.closeDeleteAllCostAzureSpJobsConfirmationForm();
    }
    this.operationInProgress = false;
    this.getCostAzureSpJobsAction;
  }

  public createCostAzureSpJobFormDialog(): void {
    this.createCostAzureSpJobForm = true;
  }

  public deleteCostAzureSpJobDialog(
    costAzureSpJob: InterfaceCostAzureSpJob
  ): void {
    this.costAzureSpJob = costAzureSpJob;
    this.deleteCostAzureSpJobConfirmationForm = true;
  }

  public deleteAllCostAzureSpJobsDialog(): void {
    this.deleteAllCostAzureSpJobsConfirmationForm = true;
  }

  public closeCreateCostAzureSpJobForm(): void {
    this.createCostAzureSpJobForm = false;
  }

  public closeDeleteCostAzureSpJobConfirmationForm(): void {
    this.deleteCostAzureSpJobConfirmationForm = false;
  }

  public closeDeleteAllCostAzureSpJobsConfirmationForm(): void {
    this.deleteAllCostAzureSpJobsConfirmationForm = false;
  }
}
