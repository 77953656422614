import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { Alert } from "@/models/Alert.model";
import moment from "moment";
import { InterfaceCostKubecostRawData } from "@/models/CostKubecostRawData.model";
import { PageData } from "@/models/PageData.model";

const costKubecostRawData = namespace("CostKubecostRawData");

interface FetchParams {
  startDate: string;
  endDate: string;
}

@Component({})
export default class CostKubecostRawData extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;

  public items: InterfaceCostKubecostRawData[] = [];
  startDate = moment().startOf("month").format("YYYY-MM-DD");
  endDate = moment().endOf("month").format("YYYY-MM-DD");
  public selectedStartDate = moment().startOf("month").format("YYYY-MM-DD");
  public selectedEndDate = moment().endOf("month").format("YYYY-MM-DD");

  public endDateMenu = false;
  public startDateMenu = false;
  public rangeDate: FetchParams = {
    startDate: this.startDate,
    endDate: this.endDate,
  };
  public operationInProgress = false;

  @costKubecostRawData.State
  public existingRawData!: InterfaceCostKubecostRawData[];

  @costKubecostRawData.State
  public inProgress!: boolean;

  @costKubecostRawData.State
  public pageDataKubecostRawData!: PageData;

  @costKubecostRawData.State
  public costKubecostRawDataTableAlert!: Alert;

  @costKubecostRawData.State
  public selectedKubecostCostsList!: InterfaceCostKubecostRawData[];

  @costKubecostRawData.Mutation
  public setRangeDate!: (data: string[]) => void;

  @costKubecostRawData.Action
  public getKubecostRawData!: (params: FetchParams) => Promise<boolean>;

  async mounted(): Promise<void> {
    this.setRangeDate(this.getDatesSelected());
    await this.getKubecostRawData({
      startDate: this.startDate,
      endDate: this.endDate,
    });
  }

  @Watch("startDate", {
    deep: true,
  })
  @Watch("endDate", {
    deep: true,
  })
  async update(): Promise<void> {
    this.setRangeDate(this.getDatesSelected());
    await this.getKubecostRawData({
      startDate: this.startDate,
      endDate: this.endDate,
    });
  }

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [
      {
        text: "ID",
        align: "start",
        value: "id",
        sortable: false,
      },
    ];
    const namespace = [
      {
        text: "Namespace",
        align: "start",
        value: "namespace",
        sortable: false,
      },
    ];
    const kubecostName = [
      {
        text: "Kubecost Name",
        align: "start",
        value: "kubecostName",
        sortable: false,
      },
    ];
    const costCenterId = [
      {
        text: "Cost Center ID",
        align: "start",
        value: "costCenterId",
        sortable: false,
      },
    ];
    const timeWindowStart = [
      {
        text: "Time Window Start",
        align: "start",
        value: "timeWindowStart",
        sortable: false,
      },
    ];
    const timeWindowEnd = [
      {
        text: "Time Window End",
        align: "start",
        value: "timeWindowEnd",
        sortable: false,
      },
    ];

    return id.concat(
      namespace,
      kubecostName,
      costCenterId,
      timeWindowStart,
      timeWindowEnd
    );
  }

  cancelStartDate(): void {
    this.startDateMenu = false;
    this.selectedStartDate = this.startDate;
  }

  selectStartDate(): void {
    this.startDateMenu = false;
    this.startDate = this.selectedStartDate;
  }

  minEndDate(): string {
    return this.startDate;
  }

  selectEndDate(): void {
    this.endDateMenu = false;
    this.endDate = this.selectedEndDate;
  }

  cancelEndDate(): void {
    this.endDateMenu = false;
    this.selectedEndDate = this.endDate;
  }

  getDatesSelected(): string[] {
    const dates: string[] = [];
    if (this.startDate && this.endDate) {
      const startDate: moment.Moment = moment(this.startDate).startOf("month");
      const endDate: moment.Moment = moment(this.endDate).endOf("month");
      const currentDate: moment.Moment = startDate.clone();
      while (currentDate.isSameOrBefore(endDate)) {
        dates.push(currentDate.format("YYYY-MM"));
        currentDate.add(1, "month");
      }
    }
    return dates;
  }
}
